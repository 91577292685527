<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2"
          @click="showModal('add')"
        >
          Add Member
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card-code
          title="Member"
          no-body
        >
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mr-1 mb-md-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                  >
                    <option :value="false">
                      ASC
                    </option>
                    <option :value="true">
                      DESC
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
          
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(name)="data">
              <div class="d-flex flex-row">
                <b-avatar v-if="data.item.logo !== null"
                  class="mr-50"
                  :src="data.item.logo"
                /> 
                <b-avatar v-else
                  class="mr-50"
                  variant="primary"
                  :text="data.item.name | initial"
                />
                <div class="d-flex flex-column">
                  <p class="m-0">{{ data.item.name }}</p>
                  <small class="text-muted">
                    {{ data.item.email | dotConcat }}
                  </small>
                </div>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge variant="light-success" 
                v-if="data.item.status === 'active'">
                Active
              </b-badge>
              <b-badge variant="light-secondary" 
                v-else-if="data.item.status === 'inactive'">
                Inactive
              </b-badge>
              <b-badge variant="light-warning" 
                v-else-if="data.item.status === 'suspended'">
                Suspended
              </b-badge>
              <b-badge variant="light-danger" 
                v-else-if="data.item.status === 'banned'">
                Banned
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  boundary="window"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="gotoDetail(data.item.id)">
                    <feather-icon
                      icon="ListIcon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Detail</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="showModal('edit', data.item.id)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteMember(data.item.id)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card-code>
      </b-col>
    </b-row>

    <b-modal 
			no-close-on-backdrop
      id="modal-add-edit"
      size="lg"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      :title="modal.title"
      v-model="modal.show"
    >
      <b-form ref="form-member">
        <b-row>
          <b-col md="4">
            <b-form-group label="First Name" label-for="first_name">
              <b-form-input
                id="name"
                v-model="input.first_name"
                placeholder="First Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Middle Name" label-for="middle_name">
              <b-form-input
                id="name"
                v-model="input.middle_name"
                placeholder="Middle Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Last Name" label-for="last_name">
              <b-form-input
                id="name"
                v-model="input.last_name"
                placeholder="Last Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Address" label-for="address_text">
              <b-form-textarea
                id="address_text"
                v-model="input.address"
                placeholder="Address"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Country" label-for="country">
              <b-form-select 
                id="country"
                v-model="input.country"
                @change="getStates()"
                :options="countries"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="State" label-for="state">
              <b-form-select 
                id="state"
                v-model="input.state"
                @change="getCities()"
                :options="states"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="City" label-for="city">
              <b-form-select
                id="city" 
                v-model="input.city"
                :options="cities"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Email" label-for="email_text">
              <b-form-input
                id="email_text"
                type="email"
                v-model="input.email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Phone Number" label-for="phoneNumber">
              <b-input-group :prepend="input.phone_code">
                <b-form-input 
                  id="phone"
                  v-model="input.contact_phone"
                  placeholder="Phone"/>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Gender" label-for="gender">
              <b-form-select 
                id="gender"
                v-model="input.gender"
                :options="$store.state.gender_options"/>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Date of Birth" label-for="dateBirth">
              <b-form-input
                id="dateBirth"
                v-model="input.dob"
                type="date"
                placeholder="Date of Birth"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Username"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="input.username"
                placeholder="Username"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label="Bussines Category" label-for="work">
              <b-form-select 
                id="work"
                v-model="input.work"
                :options="$store.state.work_options" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Occupation" label-for="occupation">
              <b-form-select 
                id="occupation"
                v-model="input.occupation"
                :options="$store.state.occupation_options" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Education" label-for="education">
              <b-form-select 
                id="education"
                v-model="input.education"
                :options="$store.state.education_options" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Provider" label-for="provider">
              <b-form-select 
                id="provider"
                v-model="input.provider"
                :options="$store.state.provider_options" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Phone Type" label-for="phoneType">
              <b-form-select 
                id="phoneType"
                v-model="input.phone_type"
                :options="$store.state.phonetype_options" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Tech Savvy"
              label-for="tectSavvy"
            >
              <b-form-checkbox
                v-model="input.savvy"
                class="custom-control-primary"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="button.disabled"
            @click="handleMember"
          >
            {{ button.loading_text }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BRow, BCol,
    BTable, BAvatar, BBadge, BCardBody, VBModal,
    BForm, BFormGroup, BFormSelect, BFormInput, BFormTextarea, BFormCheckbox,
    BPagination, BInputGroup, BInputGroupAppend, BButton, 
    BDropdown, BDropdownItem, BOverlay,
    BProgress, BProgressBar
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BCardCode,
      BRow, BCol,
      BTable, BAvatar, BBadge, BCardBody, VBModal,
      BForm, BFormGroup, BFormSelect, BFormInput, BFormTextarea, BFormCheckbox,
      BPagination, BInputGroup, BInputGroupAppend, BButton, 
      BDropdown, BDropdownItem, BOverlay,
      BProgress, BProgressBar
    },
    directives: {
    	'b-modal': VBModal,
      Ripple
    },
    data() {
      return {
        perPage: 50,
        pageOptions: [10, 50, 100],
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
					{ key: 'name', label: 'Name', sortable: true },
					{ key: 'phone', label: 'Phone' },
					{ key: 'divisi', label: 'Divisi', sortable: true },
					{ key: 'status', label: 'Status', sortable: true },
					{ key: 'actions', label: 'Actions' }
				],
        active_role: null,

        modal: {
          show: false,
          title: null,
          action: null
        },
        button: {
          disabled: false,
          loading_text: 'Submit'
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
      },
			items() {
				return this.$store.state.corporate.corporate_members
			},
      totalRows() {
        return this.$store.state.corporate.corporate_members.length
      },
      countries() {
        return this.$store.state.csc.countries
      },
      states() {
        return this.$store.state.csc.states
      },
      cities() {
        return this.$store.state.csc.cities
      },
      input() {
        let member = this.$store.state.member.detail
        return member
      }
		},
    watch: {
      'input.country'(value) {
        if(value) {
          this.$store.dispatch('csc/loadStatesByCountry', value)
        }
      },
      'input.state'(value) {
        if(value) {
          this.$store.dispatch('csc/loadCitiesByStates', value)
        }
      }
    },
    methods: {
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      // Modal action
      handleMember() {
        this.button.disabled = true
        this.button.loading_text = 'Loading...'
        
        let id = this.$route.params.id
        let type = this.modal.action

        if(type == 'add') {
          this.input.corporate_id = id
          this.$store.dispatch('member/addMember', this.input)
            .then((res) => {
              this.createToast('success', 'Data submit', res.data.message)
              this.closeModal()

              // reset data
              this.refreshData()
            })
            .catch((error) => {
              this.createToast('danger', 'Data submit', "Terjadi kesalahan input")
            })
        } else if(type == 'edit') {
          this.input.phonecode = this.input.phone_code
          this.$store.dispatch('member/editMember', this.input)
            .then((res) => {
              this.createToast('success', 'Data submit', res.data.message)
              this.closeModal()

              // reset data
              this.refreshData()
            })
            .catch((error) => {
              this.createToast('danger', 'Data submit', "Terjadi kesalahan input")
            })
        }

        this.resetButton()
      },
      showModal(type, id = null) {
        this.modal.show = true
        this.modal.action = type

        if(type == 'add') {
          this.$store.dispatch('member/resetDetailMember')
          this.$store.dispatch('csc/resetState')
          this.$store.dispatch('csc/resetCity')
          
          this.modal.title = "Add Member"
        } else if(type == 'edit') {
          this.$store.dispatch('member/loadDetailMember', id)
          this.modal.title = "Edit Member"
        }
      },
      resetButton() {
        this.button.disabled = false
        this.button.loading_text = 'Submit'
      },
      closeModal() {
        this.modal.show = false
        this.modal.action = null
        this.modal.title = null
      },

      refreshData() {
        // reset data
        let id = this.$route.params.id
				this.$store.dispatch('corporate/loadDetailCorporate', id)
      },
      getStates() {
        let country = this.input.country

        // set phone code based on country selected
        let country_filter = this.countries.filter(item => {
          return item.value == country
        })
        this.input.phone_code = country_filter[0].phonecode

        this.$store.dispatch('csc/loadStatesByCountry', country)
      },
      getCities() {
        let state = this.input.state
        this.$store.dispatch('csc/loadCitiesByStates', state)
      },
			gotoDetail(id) {
				this.$router.push({ path: `/user/member/detail/${id}` })
			},
      deleteMember(id) {
				this.$bvModal
					.msgBoxConfirm('Are you sure to delete this data?', {
						title: 'Delete confirmation',
						size: 'sm',
						okVariant: 'danger',
						okTitle: 'Delete',
						cancelTitle: 'Cancel',
						cancelVariant: 'outline-secondary',
						hideHeaderClose: false,
						centered: true,
					})
					.then(value => {
						if(value) {
							this.$store.dispatch('member/deleteMember', id)
                .then(res => {
                  this.createToast('success', 'Delete Member', res.data.message)
                  this.refreshData()
                })
                .catch(error => {
                  this.createToast('danger', 'Delete Member', "Error")
                })
						}
				})
        
      }
    }
  }
</script>