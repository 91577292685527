<template>
  <!-- 350 x 200-->
  <div>
    <b-row>
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2"
          @click="showModal('add')"
        >
          Add Banner
        </b-button>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col md="4" v-for="item in items">
        <b-card no-body class="card-developer-meetup">
          <div class="bg-light-primary rounded-top text-center">
            <b-img
              :src="item.thumbnail"
              alt="Meeting Pic"
              height="200"
              style="width: 100%"
            />
          </div>
          <b-card-body>
            <div class="meetup-header d-flex align-items-center">
              <div class="my-auto">
                <b-card-title class="mb-25">
                  {{ item.name }}
                </b-card-title>
                <b-card-text class="mb-0">
                  {{ item.description | dotConcat(150) }}
                  <div class="mt-1 mb-0" v-if="item.link">
                    <feather-icon
                      icon="LinkIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                    {{ item.link }}
                  </div>
                  <b-badge class="mt-1 mb-0" variant="light-danger" v-else>
                    Link not available
                  </b-badge>
                </b-card-text>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row> -->

    <b-card-code
      title="Banners"
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <b-form-group
            label="Sort"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="3"
            label-for="sortBySelect"
            class="mr-1 mb-md-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
                <template #first>
                  <option value="">
                    none
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
              >
                <option :value="false">
                  ASC
                </option>
                <option :value="true">
                  DESC
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      
      <b-table
        striped
        hover
        responsive
        show-empty
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(description)="data">
          {{ data.item.description | dotConcat(150) }}
        </template>
        <template #cell(link)="data">
          <div v-if="data.item.link">
            <b-badge variant="light-success" @click="goToLink(data.item.link)">
              Available
            </b-badge>
          </div>
          <div v-else>
            <b-badge variant="light-danger">
              Not Available
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              boundary="window"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="SettingsIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="showImage(data.item.thumbnail)">
                <feather-icon
                  icon="ImageIcon"
                  class="mr-50"
                  style="margin-top: -5px"
                />
                <span>View Image</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showModal('edit', data.item.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                  style="margin-top: -5px"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteBanner(data.item.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                  style="margin-top: -5px"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>

    <b-modal 
			no-close-on-backdrop
      id="modal-add-edit"
      size="lg"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      :title="modal.title"
      v-model="modal.show"
    >
      <b-form ref="form-member">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Banner Title" label-for="title">
              <b-form-input
                id="title"
                v-model="input.title"
                placeholder="Banner Title"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Banner Address" label-for="description">
              <b-form-textarea
                id="description"
                v-model="input.description"
                placeholder="Banner Address"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Banner Link" label-for="link">
              <b-form-input
                id="link"
                v-model="input.link"
                placeholder="Banner Link"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Upload Banner" label-for="link">
              <b-form-file
                ref="file-input"
                accept="image/*"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="setFileImage"
              />
            </b-form-group>
            <div v-if="input.file.url" 
              style="position: relative; width: 50%">
              <b-img
                :src="input.file.url"
                rounded
                alt="Rounded image"
                class="d-inline-block mr-1 mb-1 w-100"
              />
              <div style="position: absolute; top: 0.45rem; right: 0.5rem"
                @click="resetImage">
                <b-button
                  variant="gradient-danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="button.disabled"
            @click="handleBanner"
          >
            {{ button.loading_text }}
          </b-button>
        </div>
      </template>
    </b-modal>
    
    <b-modal
      static
      v-model="modal_crop_image"
      id="crop-image"
      ref="cropimage"
      title="Crop Gambar"
      @hidden="resetCropImage"
      @ok="handleCropImage"
    >
      <vue-cropper
        ref="cropper"
        :src="input.file_raw"
        :crop-box-resizable="false"
        :toggle-drag-mode-on-dblclick="false"
      />
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-1"
        @click="setCropArea"
      >
        Set Default Crop
      </b-button>
    </b-modal>

    <!-- Modal show image -->
    <b-modal 
      hide-footer
      no-close-on-backdrop
      id="modal-show-image"
      size="lg"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      title="Banner Image"
      v-model="modal_image.show"
    >
      <b-row>
        <b-col cols="12">
          <b-img
            :src="modal_image.thumbnail"
            class="d-inline-block mr-1 mb-1 w-100"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BRow, BCol,
    BCard, BCardTitle, BCardText,
    BTable, BAvatar, BBadge, BCardBody, VBModal, VBTooltip,
    BForm, BFormGroup, BFormSelect, BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormFile, BFormTextarea,
    BDropdown, BDropdownItem, BOverlay, BPagination,
    BProgress, BProgressBar, BImg
  } from 'bootstrap-vue'
  
  import Ripple from 'vue-ripple-directive'
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';

  export default {
    components: {
      BRow, BCol,
      BCard, BCardTitle, BCardText,
      BTable, BAvatar, BBadge, BCardBody,
      BForm, BFormGroup, BFormSelect, BFormInput, BFormFile, BFormTextarea, 
      BInputGroup, BInputGroupAppend, BButton,
      BDropdown, BDropdownItem, BOverlay, BPagination, BProgress, BProgressBar, BImg,
      BCardCode, VBModal, VBTooltip, VueCropper
    },
    directives: {
    	'b-modal': VBModal,
      Ripple,
    },
    data() {
      return {
        perPage: 50,
        pageOptions: [10, 50, 100],
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
					{ key: 'name', label: 'Name', sortable: true },
					{ key: 'link', label: 'Link' },
					{ key: 'actions', label: 'Actions' },
				],
        active_role: null,

        modal: {
          show: false,
          title: null,
          action: null
        },
        button: {
          disabled: false,
          loading_text: 'Submit'
        },
        modal_crop_image: false,
        modal_image: {
          show: false,
          thumbnail: null
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
      },
			items() {
				return this.$store.state.corporate.corporate_banner
			},
      totalRows() {
        return this.$store.state.corporate.corporate_banner.length
      },
      input() {
        return this.$store.state.banner.detail
      }
		},
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      // Modal action
      handleBanner() {
        this.button.disabled = true
        this.button.loading_text = 'Loading...'
        
        let id = this.$route.params.id
        let type = this.modal.action
        const data = new FormData()

        data.append('id', this.input.id)
        data.append('title', this.input.title)
        data.append('image', this.input.file.image)
        data.append('description', this.input.description)
        data.append('link', this.input.link)
        data.append('corporate', id)

        if(type == 'add') {
          this.$store.dispatch('banner/addBanner', data)
            .then((res) => {
              this.createToast('success', 'Data submit', res.data.message)
              this.closeModal()

              // refresh table
              this.refreshData()
            })
            .catch((error) => {
              this.createToast('danger', 'Data submit', "Terjadi kesalahan input")
            })
        } else if(type == 'edit') {
          this.$store.dispatch('banner/editBanner', this.input)
            .then((res) => {
              this.createToast('success', 'Data submit', res.data.message)
              this.closeModal()

              // refresh table
              this.refreshData()
            })
            .catch((error) => {
              this.createToast('danger', 'Data submit', "Terjadi kesalahan input")
            })
        }

        this.resetButton()
      },
      showModal(type, id = null) {
        this.modal.show = true
        this.modal.action = type

        if(type == 'add') {
          this.$store.dispatch('banner/resetBanner')
          this.modal.title = "Add Banner"
        } else if(type == 'edit') {
          this.$store.dispatch('banner/loadDetailBanner', id)
          this.modal.title = "Edit Banner"
        }
      },
      resetButton() {
        this.button.disabled = false
        this.button.loading_text = 'Submit'
      },
      closeModal() {
        this.modal.show = false
        this.modal.action = null
        this.modal.title = null
      },
      
      setFileImage(event) {
        const file = event.target.files[0]

        if (file.type.indexOf('image/') === -1) {
          this.$refs['file-input'].reset()
          this.runToast('error', 'Please select an image file')
          return;
        }

        if (typeof FileReader === 'function') {
          const reader = new FileReader();

          reader.onload = (event) => {
            let size_file = Math.floor(file.size / 1024)
            
            if(size_file <= 1024) {
              this.input.file_raw = event.target.result;
          
              this.showModalCrop()

              // rebuild cropperjs with the updated source
              this.$refs.cropper.replace(event.target.result)
            } else {
              this.$refs['file-input'].reset()
              this.runToast('error', 'Ukuran file lebih dari 1 MB')
            }
          };
          reader.readAsDataURL(file)
        } else {
          this.$refs['file-input'].reset()
          this.runToast('error', 'Sorry, FileReader API not supported')
        }
      },
      showModalCrop() {
        this.modal_crop_image = true
      },
      resetImage() {
        this.$refs['file-input'].reset()

        this.input.file.image = null
        this.input.file.url = null
        this.input.file_raw = null
      },
      setCropArea() {
        this.$refs.cropper.setCropBoxData({
          width: 350,
          height: 200
        })
      },
      resetCropImage() {
        if(!this.input.file_raw) {
          this.$refs['file-input'].reset()
        }
      },
      handleCropImage() {
        this.input.file.url = this.$refs.cropper.getCroppedCanvas().toDataURL()
        
        let block = this.input.file.url.split(";");
        let content_type = block[0].split(":")[1]
        let real_data = block[1].split(",")[1];
        this.input.file.image = this.b64toBlob(real_data, content_type)
      },
      b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
      },

      goToLink(link) {
        window.open(link, '_blank')
      },
      showImage(thumbnail) {
        this.modal_image.show = true
        this.modal_image.thumbnail = thumbnail
      },
      deleteBanner(id) {
        this.$bvModal
					.msgBoxConfirm('Are you sure to delete this data?', {
						title: 'Delete confirmation',
						size: 'sm',
						okVariant: 'danger',
						okTitle: 'Delete',
						cancelTitle: 'Cancel',
						cancelVariant: 'outline-secondary',
						hideHeaderClose: false,
						centered: true,
					})
					.then(value => {
						if(value) {
							this.$store.dispatch('banner/deleteBanner', id)
                .then((res) => {
                  this.createToast('success', 'Delete data banner', res.data.message)
                  this.closeModal()

                  // refresh table
                  this.refreshData()
                })
                .catch((error) => {
                  this.createToast('danger', 'Delete data banner', "Terjadi kesalahan input")
                })
						}
				})
      },
      refreshData() {
        // reset data
        let id = this.$route.params.id
        this.$store.dispatch('corporate/loadBannerCorporate', id)
      }
    }
  }
</script>